import * as moment from 'moment';
import { Notification } from 'app/layout/common/notifications/notifications.types';

/* tslint:disable:max-line-length */
export const notifications: Notification[] = [
    {
        id: '493190c9-5b61-4912-afe5-78c21f1044d7',
        icon: 'check_circle',
        title: 'Получено плащане',
        description: 'Успешно заплатихте вноска по кредит № 123456',
        time: moment().subtract(25, 'minutes').toISOString(), // 25 minutes ago
        read: false
    },

];
