import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { UserModel } from 'app/shared/models/user-model';

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user'
})
export class UserComponent implements OnInit {

    @Input()
    user: UserModel;


    constructor(private router: Router) { }

    ngOnInit(): void {

    }

    goToProfile() {
        this.router.navigate(['profile']);
    }

    /**
     * Sign out
     */
    signOut(): void {
        this.router.navigate(['/sign-out']);
    }
}
