import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe extends DatePipe implements PipeTransform {

  transform(value: Date, ...args: any[]): any {
    const format = 'dd.MM.yyyy';
    return super.transform(value, format);
  }

}
