import { AngularFireStorage } from "@angular/fire/storage";
import { AngularFireAuth } from "@angular/fire/auth";
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { TreoModule } from '@treo';
import { TreoConfigModule } from '@treo/services/config';
import { TreoMockApiModule } from '@treo/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockDataServices } from 'app/data/mock';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { environment } from 'environments/environment';
import { AngularFireFunctions, AngularFireFunctionsModule, REGION } from "@angular/fire/functions";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireModule } from '@angular/fire';

import { USE_EMULATOR as AUTH_EMULATOR } from '@angular/fire/auth';
import { USE_EMULATOR as FIRESTORE_EMULATOR } from '@angular/fire/firestore';
import { USE_EMULATOR as FUNCTIONS_EMULATOR } from '@angular/fire/functions'




const routerConfig: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    preloadingStrategy: PreloadAllModules,
    relativeLinkResolution: 'legacy',
};

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        // Treo & Treo Mock API
        TreoModule,
        TreoConfigModule.forRoot(appConfig),
        TreoMockApiModule.forRoot(mockDataServices),

        BrowserAnimationsModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule,
        AngularFireFunctionsModule,
        AngularFirestoreModule,

        // Core
        CoreModule,

        // Layout
        LayoutModule,

        // 3rd party modules
        MarkdownModule.forRoot({})
    ],
    providers: [
        { provide: REGION, useValue: 'europe-west1' },
        // {
        //     provide: AUTH_EMULATOR,
        //     useValue: environment.production ? undefined : ['localhost', 9099],
        // },
        // {
        //     provide: FUNCTIONS_EMULATOR,
        //     useValue: environment.production ? undefined : ['localhost', 5001],
        // },
        // {
        //     provide: FIRESTORE_EMULATOR,
        //     useValue: environment.production ? undefined : ['localhost', 8080],
        // },
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {

    constructor(cloudFunctions: AngularFireFunctions, auth: AngularFireAuth) {
        // if (!environment.production) {
        //   //  cloudFunctions.useFunctionsEmulator('http://localhost:5001');
        //     //     auth.useEmulator('http://localhost:9099')
        // }

    }
}
