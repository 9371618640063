import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'period'
})
export class PeriodPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    let txt = '';

    if (value) {
      if (value === 1) {
        txt = value + ' месец';
      } else {
        txt = value + ' месецa';
      }
    }
    return txt;
  }

}
