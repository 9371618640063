import { AngularFireAuthGuard, canActivate, redirectLoggedInTo, redirectUnauthorizedTo } from "@angular/fire/auth-guard";
import { AuthGuard } from "./core/auth/guards/auth.guard";
import { Route } from '@angular/router';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['sign-in']);
const redirectLoggedInToItems = () => redirectLoggedInTo(['signed-in-redirect']);

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'request' },

    // Redirect signed in user to the '/example'
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'request' },

    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule) },
            { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule) },
            { path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule) },
            {
                path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule), ...canActivate(redirectLoggedInToItems)

            },
            { path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule), ...canActivate(redirectLoggedInToItems) },
            { path: 'auth', loadChildren: () => import('app/modules/auth/auth/auth.module').then(m => m.AuthModule) },
        ]
    },

    // Admin routes
    {
        path: '',
        component: LayoutComponent,

        data: {
            layout: 'basic',
        },
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [

            // Example
            { path: 'request', loadChildren: () => import('app/modules/admin/request/request.module').then(m => m.RequestModule), ...canActivate(redirectUnauthorizedToLogin) },
            { path: 'loans', loadChildren: () => import('app/modules/admin/loans/loans.module').then(m => m.LoansModule), ...canActivate(redirectUnauthorizedToLogin) },
            { path: 'profile', loadChildren: () => import('app/modules/admin/profile/profile.module').then(m => m.ProfileModule), ...canActivate(redirectUnauthorizedToLogin) },
            { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule), ...canActivate(redirectUnauthorizedToLogin) },
        ]
    },
    {
        path: '**',
        redirectTo: '',
        pathMatch: 'full'
    }


];
