export class UserModel {
    uid: string;
    email: string;
    displayName: string;
    fistName: string;
    lastName: string;
    emailVerified: boolean;
    pin: string;
    phone: string;

    constructor(init: Partial<UserModel>) {

        Object.assign(this, init);
    }
}
