import { UserModel } from "./../../shared/models/user-model";
import { User } from "./../../layout/common/user/user.types";
import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of, throwError } from 'rxjs';
import { map, switchMap, take, tap } from "rxjs/operators";
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';

@Injectable()
export class AuthService {
    // Private
    private _authenticated: boolean;

    user$: BehaviorSubject<
        UserModel
    > = new BehaviorSubject<UserModel>(null);

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(private afAuth: AngularFireAuth, private router: Router, private db: AngularFirestore, private fns: AngularFireFunctions
    ) {
        // Set the defaults
        this._authenticated = false;

        this.afAuth.authState.pipe(
            switchMap(user => {
                if (user) {

                    return this.db.doc<any>(`users/${user.uid}`).valueChanges().pipe(map(item => {
                        let model = {};
                        Object.assign(model, { uid: user.uid, email: user.email, emailVerified: user.emailVerified, displayName: user.displayName });
                        Object.assign(model, item);
                        return new UserModel(model);
                    }));
                } else {
                    // Logged out
                    return of(null);
                }
            })
        ).subscribe(user => {
            if (user) {

                this.afAuth.idToken.pipe(
                    take(1)).subscribe(token => {
                        this._authenticated = true;
                        this.accessToken = token;
                        this.user$.next(user);
                    });
            } else {

                this._authenticated = false;
                localStorage.removeItem('access_token');
                this.user$.next(null);
            }

        });


    }



    reloadUser() {
        this.afAuth.authState.subscribe(loggedIn => {
            console.log('logged id', loggedIn);
            if (loggedIn) {
                this.afAuth.currentUser.then(user => {
                    console.log('reloadUser');

                    user.reload().then(() => {
                        this.db.doc<any>(`users/${user.uid}`).set({ emailVerified: user.emailVerified }, { merge: true });
                    });
                });
            }
        })

    }


    set accessToken(token: string) {
        localStorage.setItem('access_token', token);
    }

    get accessToken(): string {
        return localStorage.getItem('access_token');
    }

    get isLoggedIn(): boolean {
        const token = localStorage.getItem('access_token');
        return (token !== null) ? true : false;
    }


    signIn(email: string, password: string): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        return from(this.afAuth
            .signInWithEmailAndPassword(email, password)).pipe(switchMap((response: any) => {
                return this.afAuth.idToken.pipe(take(1), switchMap(idToken => {
                    this._authenticated = true;
                    this.accessToken = idToken;
                    return of(response);
                }));
            }));
    }

    signUp(email: string, password: string): Promise<any> {
        return this.afAuth.createUserWithEmailAndPassword(email, password);
    }

    signOut(): void {
        this.afAuth.signOut().then(() => {
            this._authenticated = false;
            localStorage.removeItem('access_token');
        });
    }

    resetPassword(email: string): Promise<void> {
        return this.afAuth.sendPasswordResetEmail(email);

    }

    verifyPasswordResetCode(code: string): Promise<string> {
        return this.afAuth.verifyPasswordResetCode(code);
    }

    confirmPasswordReset(code: string, password: string): Promise<void> {
        return this.afAuth.confirmPasswordReset(code, password);
    }

    confirmEmail(code: string): Promise<void> {
        return this.afAuth.applyActionCode(code);
    }

    sendVerificationEmail() {
        this.afAuth.currentUser.then(user => {

            user.sendEmailVerification().then(() => {

                this.router.navigate(['request']);

            });
        });
    }

    getProfile(): Observable<any> {
        return this.user$;
    }

    getProfileForm(): Observable<any> {
        return this.user$.pipe(
            switchMap(user => this.db.collection('users_updates').doc(user.uid).get().pipe(
                map((row: any) => {
                    if (row.exists) {
                        return row.data();
                    }
                    // Return the new message from observable
                    return user;
                })
            ))
        );
    }

    hasProfileUpdateRequest(): Observable<boolean> {

        return this.user$.pipe(
            switchMap(user => this.db.collection('users_updates').doc(user.uid).snapshotChanges().pipe(
                map((row: any) => {
                    return row.payload.exists;

                })
            ))
        );
    }

    updateUserMarketingPreference(marketingPreference, publicFunction): Observable<any> {
        return this.fns.httpsCallable('updateUserMarketingPreference')({
            marketingPreference: marketingPreference == 1 ? true : false, 
            publicFunction: publicFunction == 1 ? true : false});
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        //  Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        console.log('this.accessToken', this.accessToken);
        if (!this.accessToken) {
            console.log('returnfalse');
            return of(false);
        }

        return of(this.isLoggedIn);

        // Check the access token expire date
        // if (AuthUtils.isTokenExpired(this.accessToken)) {
        //     return of(false);
        // }

        // If the access token exists and it didn't expire, sign in using it
        //  return this.signInUsingToken();
    }
}
