import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CurrencySymbolPipe } from './pipes/currency-symbol.pipe';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { PeriodPipe } from './pipes/period.pipe';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CurrencySymbolPipe,
        CustomDatePipe,
        PeriodPipe
    ],
    declarations: [CurrencySymbolPipe, CustomDatePipe, PeriodPipe]
})
export class SharedModule {
}
