<!-- Button -->
<button class="icon-button" mat-icon-button [matMenuTriggerFor]="userActions">
    <mat-icon [svgIcon]="'account_circle'"></mat-icon>
</button>

<mat-menu class="user-actions-menu" [xPosition]="'before'" #userActions="matMenu">
    <button mat-menu-item>
        <span class="user-info">
            <span>E-mail адреса:</span>
            <span class="email">{{user?.email}}</span>
        </span>
    </button>
    <ng-container *ngIf="user?.pin">
        <mat-divider class="my-2"></mat-divider>
        <button mat-menu-item (click)="goToProfile()">
            <mat-icon [svgIcon]="'account_circle'"></mat-icon>
            <span>Кориснички профил</span>
        </button>
        <mat-divider class="my-2"></mat-divider>
    </ng-container>
    <button mat-menu-item (click)="signOut()">
        <mat-icon [svgIcon]="'power_settings_new'"></mat-icon>
        <span>Излез</span>
    </button>
</mat-menu>