<!-- Navigation -->
<treo-vertical-navigation [appearance]="'classic'" [mode]="isScreenSmall ? 'over' : 'side'" [name]="'mainNavigation'"
    [navigation]="navigation" [opened]="!isScreenSmall">

    <div treoVerticalNavigationHeader>
        <div class="logo">
            <img class="logo-text" src="assets/images/logo/logo.png">
        </div>
    </div>

</treo-vertical-navigation>

<!-- Wrapper -->
<div class="wrapper">

    <!-- Header -->
    <div class="header">

        <!-- Navigation toggle button -->
        <button class="navigation-toggle-button" mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'menu'"></mat-icon>
        </button>

        <!-- Spacer -->
        <div class="spacer"></div>


        <!-- User -->


        <user *ngIf="authService.user$ | async as user" [user]="user"></user>


    </div>

    <!-- Content -->
    <div class="content">

        <!-- *ngIf="true" hack is required here for router-outlet to work correctly. Otherwise,
             it won't register the changes on the layout and won't update the view. -->
        <router-outlet *ngIf="true"></router-outlet>

    </div>

    <!-- Footer -->
    <div class="footer">
        CrediYES MK - Ние ДАваме пари!
    </div>

</div>